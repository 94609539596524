import { Link, withPrefix } from 'gatsby'
import React from 'react'

import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(
  createStyles({
    title: {
      padding: 30,
      fontSize: 12,
      textAlign: 'center'
    }
  })
)

const Footer = () => {
  const classes = useStyles()

  return (
    <p className={classes.title}>© ordinary-person.work / <Link to={withPrefix('/privacy-policy/')}>プライバシーポリシー</Link> / <a href='https://docs.google.com/forms/d/e/1FAIpQLSfefEeX8M_AiDFUT1jEcfOWnA1KOzPOcKWZ00Y7kWf4WlvRDg/viewform'>お問い合わせ</a></p>
  )
}

export default Footer
