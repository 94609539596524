import { Link, withPrefix } from 'gatsby'
import React from 'react'

import {
    AppBar, Avatar, Button, Divider, Drawer, Hidden, IconButton, List, ListItem, ListItemText,
    ListSubheader, Toolbar
} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'

import BlogCategory from '../../models/blogCategory/blogCategory'
import SEOConfig from '../../models/seoConfig/seoConfig'
import PageType from '../../models/siteConfig/pageType'

type Props = {
  seoConfig: SEOConfig
  categoryList: BlogCategory[]
}

const useStyles = makeStyles(
  createStyles({
    position: {
      textAlign: 'center'
    },
    titleFont: {
      margin: '15px 20px',
      fontSize: 20,
      fontWeight: 600,
      height: '1.4em',
      lineHeight: 1.4,
      overflow: `hidden`,
    },
    button: {
      width: 100,
      margin: '0 10px 0 15px'
    },
    menuIcon: {
      margin: '0 auto 0 0px'
    },
    menuP: {
    },
    menuButton: {
      margin: '0 0 0 auto'
    },
    side: {
      width: 200
    },
    sideFont: {
      color: grey[600],
      fontWeight: 1200,
      height: '1.4em',
      lineHeight: 1.4,
      overflow: `hidden`,
    }
  })
)

const Header = ({ seoConfig, categoryList }: Props) => {
  const classes = useStyles()

  const [state, setState] = React.useState(
    { isOpened: false }
  )

  const toggleDrawer = (isOpened: boolean) => () => {
    setState({ ...state, isOpened: isOpened })
  }

  const sideList = () => (
    <div
      className={classes.side}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <List
        subheader={
          <ListSubheader component="div" >
            ページ
          </ListSubheader>
        }>
        <Link to={'/'} style={{ textDecoration: 'none' }}>
          <ListItem button key={"Top"} >
            <ListItemText primary="Top" className={classes.sideFont} />
          </ListItem>
        </Link>
        <Link to={'/blog'} style={{ textDecoration: 'none' }}>
          <ListItem button key={"Blog"} >
            <ListItemText primary="Blog" className={classes.sideFont} />
          </ListItem>
        </Link>
        <Link to={'/vlog'} style={{ textDecoration: 'none' }}>
          <ListItem button key={"Vlog"} >
            <ListItemText primary="Vlog" className={classes.sideFont} />
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List
        subheader={
          <ListSubheader component="div" >
            カテゴリーリスト
          </ListSubheader>
        }>
        {categoryList.map(category => (
          <Link to={'/category/' + category.slug} style={{ textDecoration: 'none' }}>
            <ListItem button key={category.slug}>
              <ListItemText primary={category.name} className={classes.sideFont} />
            </ListItem>
          </Link>
        ))}
      </List>
      <Divider />
      <List
        subheader={
          <ListSubheader component="div" >
            このサイトについて
          </ListSubheader>
        }>
        <Link to={'/privacy-policy'} style={{ textDecoration: 'none' }}>
          <ListItem button key={"privacy-policy"} >
            <ListItemText primary="プライバシーポリシー" className={classes.sideFont} />
          </ListItem>
        </Link>
        <a href={'https://docs.google.com/forms/d/e/1FAIpQLSfefEeX8M_AiDFUT1jEcfOWnA1KOzPOcKWZ00Y7kWf4WlvRDg/viewform'} style={{ textDecoration: 'none' }}>
          <ListItem button key={"contact"} >
            <ListItemText primary="お問い合わせ" className={classes.sideFont} />
          </ListItem>
        </a>
      </List>
    </div>
  )

  return (
    <>
      <AppBar position="sticky" color='default'>
        <Toolbar>
          <Link to='/' style={{ textDecoration: 'none' }}>
            <Avatar className={classes.menuIcon} alt="Main Icon" src={withPrefix('/main-icon.png')} />
          </Link >
          <Hidden only='xs'>
            <Link to='/' style={{ textDecoration: 'none' }}>
              {seoConfig.isTop() ? <h1 className={classes.titleFont}>{seoConfig.siteConfig.title}</h1> : <p className={classes.titleFont}>{seoConfig.siteConfig.title}</p>}
            </Link >
          </Hidden>
          <Link to='/blog' style={{ textDecoration: 'none' }}>
            {seoConfig.pageType === PageType.Blog ?
              <Button variant="contained" className={classes.button}>Blog</Button>
              : <Button variant="outlined" className={classes.button}>Blog</Button>
            }
          </Link >
          <Link to='/vlog' style={{ textDecoration: 'none' }}>
            {seoConfig.pageType === PageType.Vlog ?
              <Button variant="contained" color="secondary" className={classes.button}>▶ Vlog</Button>
              : <Button variant="outlined" color="secondary" className={classes.button}>▶ Vlog</Button>
            }
          </Link >
          <IconButton edge="start" color="inherit" aria-label="menu" className={classes.menuButton} onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Drawer anchor="right" open={state.isOpened} onClose={toggleDrawer(false)}>
            {sideList()}
          </Drawer>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Header
