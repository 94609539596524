import PageType from '../siteConfig/pageType'
import SiteConfig from '../siteConfig/siteConfig'

class SEOConfig {
  pageType: PageType
  postTitle: string | null
  postDescription: string | null
  postSlug: string | null
  siteConfig: SiteConfig
  ogpImageSrc: string
  categorySlug: string | null
  isYoutube: boolean | false
  videoIframe: string | null

  constructor(
    pageType: PageType,
    postTitle: string | null,
    postDescription: string | null,
    postSlug: string | null,
    siteConfig: SiteConfig,
    ogpImageSrc: string,
    categorySlug: string | null,
    isYoutube: boolean | false,
    videoIframe: string | null
  ) {
    this.pageType = pageType
    this.postTitle = postTitle
    this.postDescription = postDescription
    this.postSlug = postSlug
    this.siteConfig = siteConfig
    this.ogpImageSrc = ogpImageSrc
    this.categorySlug = categorySlug
    this.isYoutube = isYoutube
    this.videoIframe = videoIframe
  }

  isTop(): boolean {
    if (this.pageType === PageType.TOP) {
      return true
    }
    else {
      return false
    }
  }

  title(): string {
    if (this.pageType === PageType.TOP) {
      return this.siteConfig.title
    }
    else if (this.pageType === PageType.Blog) {
      return `Blog - ${this.siteConfig.title}`
    }
    else if (this.pageType === PageType.Vlog) {
      return `Vlog - ${this.siteConfig.title}`
    }
    else if (this.pageType === PageType.PrivacyPolicy) {
      return `プライバシーポリシー - ${this.siteConfig.title}`
    }
    else if (this.pageType === PageType.Category) {
      return this.postTitle + ' - ' + this.siteConfig.title
    }
    else {
      return this.postTitle + ' - ' + this.siteConfig.title
    }
  }

  description(): string | null {
    if (this.pageType === PageType.TOP || this.pageType === PageType.Blog || this.pageType === PageType.Vlog) {
      return this.siteConfig.description
    }
    else if (this.pageType === PageType.PrivacyPolicy) {
      return `プライバシーポリシー - ${this.siteConfig.title}`
    }
    else if (this.pageType === PageType.Category) {
      return this.postTitle + ' - ' + this.siteConfig.title
    }
    else {
      return this.postDescription
    }
  }

  ogpType(): string {
    if (this.pageType === PageType.TOP || this.pageType === PageType.Blog || this.pageType === PageType.Vlog) {
      return 'website'
    }
    else if (this.pageType === PageType.PrivacyPolicy) {
      return 'website'
    }
    else if (this.pageType === PageType.Category) {
      return 'blog'
    }
    else {
      return 'blog'
    }
  }

  ogpURL(): string {
    if (this.pageType === PageType.TOP) {
      return this.siteConfig.baseURL
    }
    else if (this.pageType === PageType.Vlog) {
      return this.siteConfig.baseURL + '/vlog/'
    }
    else if (this.pageType === PageType.Blog) {
      return this.siteConfig.baseURL + '/blog/'
    }
    else if (this.pageType === PageType.PrivacyPolicy) {
      return this.siteConfig.baseURL + '/privacy-policy/'
    }
    else if (this.pageType === PageType.Category) {
      return this.siteConfig.baseURL + '/category/' + this.categorySlug + '/'
    }
    else {
      return this.siteConfig.baseURL + '/' + this.postSlug + '/'
    }
  }

  twitterCardType(): string {
    if (this.pageType === PageType.TOP || this.pageType === PageType.Blog || this.pageType === PageType.Vlog) {
      return 'summary'
    }
    else if (this.pageType === PageType.PrivacyPolicy) {
      return 'summary'
    }
    else if (this.pageType === PageType.Category) {
      return 'summary'
    }
    else {
      if (this.isYoutube) {
        return 'summary_large_image' // ほんとうは 'player'
      } else {
        return 'summary_large_image'
      }
    }
  }

  ogpImage(): string {
    if (this.pageType === PageType.TOP || this.pageType === PageType.Blog || this.pageType === PageType.Vlog) {
      return this.ogpImageSrc
    }
    else if (this.pageType === PageType.PrivacyPolicy) {
      return this.ogpImageSrc
    }
    else if (this.pageType === PageType.Category) {
      return this.ogpImageSrc
    }
    else {
      return 'http:' + this.ogpImageSrc
    }
  }

}

export default SEOConfig
