enum PageType {
    TOP,
    Blog,
    Vlog,
    Article,
    Category,
    PrivacyPolicy
}

export default PageType
