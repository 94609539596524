import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

import { Card, Grid, Hidden } from '@material-ui/core'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import { grey } from '@material-ui/core/colors'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import { BlogPost } from '../../models/blogPost/blogPost'
import Categories from './categories'

type Props = {
  blogPost: BlogPost
}

const useStyles = makeStyles(
  createStyles({
    title: {
      display: 'none'
    },
    cardMargin: {
      margin: 5,
    },
    image: {
      filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,1))'
    },
    date: {
      textAlign: 'right',
      marginTop: 5,
      marginBottom: -10,
      fontSize: 12,
      color: grey[500],
    },
    cardTitle: {
      margin: 0,
      height: '2.8em',
      lineHeight: 1.4,
      overflow: `hidden`,
      letterSpacing: 2,
      color: grey[700]
    },
    description: {
      margin: 3,
      height: '4.2em',
      lineHeight: 1.4,
      overflow: `hidden`,
      letterSpacing: 1,
      color: grey[400]
    },
    chip: {
      margin: 5
    },
    chips: {
      margin: 3,
      height: 30,
      overflow: `hidden`,
    }
  })
)

const BlogCard = ({ blogPost }: Props) => {
  const classes = useStyles()

  return (
    <Grid item xs={12} sm={6}>
      <Link to={blogPost.slug} style={{ textDecoration: 'none' }}>
        <Card className={classes.cardMargin}>
          <CardActionArea>
            <Img sizes={{ ...blogPost.heroImage.fluid, aspectRatio: 1.91 }} className={classes.image} />
            <CardContent>
              <h3 className={classes.cardTitle}> {blogPost.title}</h3>
              <div className={classes.description}>{blogPost.description.description}</div>
              <Categories categories={blogPost.categories} />
              <div className={classes.date}>{blogPost.createdAt}</div>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </Grid>
  )
}

export default BlogCard
