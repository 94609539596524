import { Link } from 'gatsby'
import React from 'react'

import { Grid, makeStyles } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'

import BlogCategory from '../../models/blogCategory/blogCategory'

interface Props {
  categories: BlogCategory[]
}

const useStyles = makeStyles({
  categoryArea: {
    padding: 3
  },
  chip: {
    padding: 3
  }
})

const Categories = ({ categories }: Props) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.categoryArea}>
      {categories.map((category: BlogCategory) => (
        <Link to={'/category/' + category.slug} style={{ textDecoration: 'none' }} className={classes.chip}>
          <Chip label={'#' + category.name} />
        </Link>
      ))}
    </Grid>
  )
}

export default Categories

