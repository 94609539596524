import get from 'lodash/get'
import React, { Component } from 'react'
import {
    FacebookIcon, FacebookShareButton, LineIcon, LineShareButton, LinkedinIcon, LinkedinShareButton,
    TwitterIcon, TwitterShareButton
} from 'react-share'

import { withStyles } from '@material-ui/styles'

const styles = {
  shareButtonsArea: {
    margin: 3,
    textAlign: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  hatena: {
    margin: 3,
    textAlign: 'center',
    textDecoration: 'none',
    width: '64px',
    height: '64px',
    lineHeight: '64px',
    color: 'white',
    background: `linear-gradient(
      #00a5e0 0%,
      #00a5e0 50%,
      #008de1 50%,
      #008de1 100%
    )`,
    borderRadius: '10%'
  }
}

class ShareButtons extends Component {
  render() {
    const styles = get(this, 'props.classes')
    const shareURL = get(this, 'props.url')

    return (
      <div className={styles.shareButtonsArea} >
        <FacebookShareButton url={shareURL} className={styles.shareButtonsArea}>
          <FacebookIcon round size={54} />
        </FacebookShareButton>
        <TwitterShareButton url={shareURL} className={styles.shareButtonsArea}>
          <TwitterIcon round size={54} />
        </TwitterShareButton>
        <LineShareButton url={shareURL} className={styles.shareButtonsArea}>
          <LineIcon round size={54} />
        </LineShareButton>
        <LinkedinShareButton url={shareURL} className={styles.shareButtonsArea}>
          <LinkedinIcon round size={54} />
        </LinkedinShareButton>
        <a
          className={styles.shareButtonsArea}
          href={`http://b.hatena.ne.jp/add?mode=confirm&url=${shareURL}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* <svg role="img" viewBox="0 0 64 64" width="64" height="64" >
            <circle cx="32" cy="32" r="31" fill="#00A4DE"></circle>
            <path d="M20.47 0C22.42 0 24 1.58 24 3.53v16.94c0 1.95-1.58 3.53-3.53 3.53H3.53C1.58 24 0 22.42 0 20.47V3.53C0 1.58 1.58 0 3.53 0h16.94zm-3.705 14.47c-.78 0-1.41.63-1.41 1.41s.63 1.414 1.41 1.414 1.41-.645 1.41-1.425-.63-1.41-1.41-1.41zM8.61 17.247c1.2 0 2.056-.042 2.58-.12.526-.084.976-.222 1.32-.412.45-.232.78-.564 1.02-.99s.36-.915.36-1.48c0-.78-.21-1.403-.63-1.87-.42-.48-.99-.734-1.74-.794.66-.18 1.156-.45 1.456-.81.315-.344.465-.824.465-1.424 0-.48-.103-.885-.3-1.26-.21-.36-.493-.645-.883-.87-.345-.195-.735-.315-1.215-.405-.464-.074-1.29-.12-2.474-.12H5.654v10.486H8.61zm.736-4.185c.705 0 1.185.088 1.44.262.27.18.39.495.39.93 0 .405-.135.69-.42.855-.27.18-.765.254-1.44.254H8.31v-2.297h1.05zm8.656.706v-7.06h-2.46v7.06H18zM8.925 9.08c.71 0 1.185.08 1.432.24.245.16.367.435.367.83 0 .38-.13.646-.39.804-.265.154-.747.232-1.452.232h-.57V9.08h.615z" fill="white" />
          </svg> */}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" width="54px" height="54px">
            <circle cx="250" cy="250" r="245" fill="#00a4de" />
            <g fill="#fff">
              <path d="M278.2,258.1q-13.6-15.2-37.8-17c14.4-3.9,24.8-9.6,31.4-17.3s9.8-17.8,9.8-30.7A55,55,0,0,0,275,166a48.8,48.8,0,0,0-19.2-18.6c-7.3-4-16-6.9-26.2-8.6s-28.1-2.4-53.7-2.4H113.6V363.6h64.2q38.7,0,55.8-2.6c11.4-1.8,20.9-4.8,28.6-8.9a52.5,52.5,0,0,0,21.9-21.4c5.1-9.2,7.7-19.9,7.7-32.1C291.8,281.7,287.3,268.2,278.2,258.1Zm-107-71.4h13.3q23.1,0,31,5.2c5.3,3.5,7.9,9.5,7.9,18s-2.9,14-8.5,17.4-16.1,5-31.4,5H171.2V186.7Zm52.8,130.3c-6.1,3.7-16.5,5.5-31.1,5.5H171.2V273h22.6c15,0,25.4,1.9,30.9,5.7s8.4,10.4,8.4,20S230.1,313.4,223.9,317.1Z" />
              <path d="M357.6,306.1a28.8,28.8,0,1,0,28.8,28.8A28.8,28.8,0,0,0,357.6,306.1Z" />
              <rect x="332.6" y="136.4" width="50" height="151.52" />
            </g>
          </svg>
        </a>
      </div >
    )
  }
}

export default withStyles(styles)(ShareButtons)
