import { withPrefix } from 'gatsby'
import React from 'react'
import AdSense from 'react-adsense'

import { Avatar, Container, Grid, SvgIcon } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import BlogCategory from '../../models/blogCategory/blogCategory'
import SiteConfig from '../../models/siteConfig/siteConfig'
import Categories from '../molecules/categories'

const useStyles = makeStyles(
  createStyles({
    avatar: {
      margin: 5,
      width: 120,
      height: 120,
    },
    social: {
      marginTop: 7,
      textAlign: 'center'
    },
    author: {
      margin: 5,
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 600
    },
    bio: {
      padding: 5,
      fontSize: 14,
      fontWeight: 300,
      color: grey[500]
    }
  })
)

type Props = {
  siteConfig: SiteConfig,
  blogCategories: BlogCategory[]
}

const SideBar = ({ siteConfig, blogCategories }: Props) => {
  const classes = useStyles()

  return (
    <Container>
      <Grid container justify='center' >
        <Avatar src={withPrefix('/main-icon.png')} className={classes.avatar} />
      </Grid>
      <p className={classes.author}>{siteConfig.author.name}</p>
      <p className={classes.author}>{siteConfig.author.nameCall}</p>
      <Grid container justify='space-around'>
        {siteConfig.author.medias.map((media) => (
          <Grid item xs={4} className={classes.social}>
            <a href={media.link} target='_blank' rel="noopener noreferrer">
              <SvgIcon viewBox={media.viewBox} fontSize='large'>
                {media.paths.map((path) => (
                  <path d={path.d} fill={path.fill} />
                ))}
              </SvgIcon>
            </a>
          </Grid>
        ))}
      </Grid>
      <p className={classes.bio}>{siteConfig.author.bio}</p>
      <div>
        <AdSense.Google
          style={{ display: 'block' }}
          client='ca-pub-5524131539322102'
          slot='1859844250'
          format='auto'
          responsive='true'
        />
      </div>
      <p className={classes.author}>Tags</p>
      <Categories categories={blogCategories} />
    </Container>
  )
}

export default SideBar
