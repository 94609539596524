import React from 'react'
import AdSense from 'react-adsense'

import { Grid } from '@material-ui/core'

import { AllBlogPost } from '../../models/blogPost/blogPost'
import BlogCard from '../molecules/blogCard'

type Props = {
  blogPosts: Array<AllBlogPost>
}

const BlogList = ({ blogPosts }: Props) => {

  const adsenseIndex = 1 // FV対策

  return (
    <Grid container>
      {blogPosts.map(({ node }, index) => {
        return (
          <>
            <BlogCard blogPost={node} />
            {index === adsenseIndex &&
              <Grid item xs={12} sm={6}>
                <AdSense.Google
                  client='ca-pub-5524131539322102'
                  slot='4192803054'
                  style={{ display: 'block' }}
                  format='fluid'
                  layoutKey='-5i+bq-64-8l+1kt'
                />
              </Grid>
            }
          </>
        )
      })}
    </Grid>)
}

export default BlogList
