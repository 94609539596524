
import 'ress'

import React from 'react'
import Helmet from 'react-helmet'

import { createMuiTheme, Grid, ThemeProvider } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import BlogCategory from '../models/blogCategory/blogCategory'
import { ArticleGoogleStructuredConfig } from '../models/seoConfig/ArticleGoogleStructure'
import SEOConfig from '../models/seoConfig/seoConfig'
import Footer from './organisms/footer'
import Header from './organisms/header'
import SideBar from './organisms/sidebar'

interface Props {
  seoConfig: SEOConfig,
  blogCategories: BlogCategory[]
  articleStructured: ArticleGoogleStructuredConfig
  children: React.ReactNode
}

const useStyles = makeStyles(
  createStyles({
    siteWidth: {
      maxWidth: 1040,
      margin: 'auto'
    },
    font: {
      backgroundColor: '#fcfafb',
      letterSpacing: 1,
      fontFamily: '"Noto Sans CJK JP", "NotoSansCJKjp", "FontAwesome", "Hiragino Kaku Gothic ProN", "Meiryo", sans-serif'
    },
    contentsArea: {
      padding: 10
    }
  })
)

const theme = createMuiTheme({
  palette: {
    primary: { // Amazon
      main: '#FF9900',
      contrastText: '#fafafa'
    },
    secondary: { // 楽天, Vlog
      main: '#B31B1B'
    },
  },
})

const Layout = ({ seoConfig, blogCategories, articleStructured, children }: Props) => {
  const classes = useStyles()

  let meta = [
    // search console
    { name: 'google-site-verification', content: 'ZHRuOslNGGfSEmRZDWzoSmrusb-wNKcfaLQZhgVfmdc' },
    // site info
    { name: 'author', content: seoConfig.siteConfig.author.name },
    { name: 'description', content: seoConfig.description() },
    // ogp
    { property: 'og:title', content: seoConfig.title() },
    { property: 'og:type', content: seoConfig.ogpType() },
    { property: 'og:image', content: seoConfig.ogpImage() },
    { property: 'og:description', content: seoConfig.description() },
    { property: 'og:url', content: seoConfig.ogpURL() },
    { property: 'og:site_name', content: seoConfig.siteConfig.title },
    // facebook app id
    { property: 'fb:app_id', content: '895797824092860' },
    // twiter card
    { name: 'twitter:card', content: seoConfig.twitterCardType() },
    { name: 'twitter:site', content: '@ordinary_p_w' },
    { name: 'twitter:creator', content: '@ordinary_p_w' }
  ]

  if (seoConfig.isYoutube) {
    meta.push({ name: 'twitter:player', content: seoConfig.videoIframe })
    meta.push({ name: 'twitter:player:width', content: '360' })
    meta.push({ name: 'twitter:player:height', content: '200' })
  }

  return (
    <>
      {/* 
      // @ts-ignore */}
      <Helmet
        title={seoConfig.title()}
        meta={meta}
      >
        <html lang='ja' />
        {/* Adsense tag */}
        <script data-ad-client="ca-pub-5524131539322102" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
        {/* Adsense AMP tag */}
        <script async custom-element="amp-auto-ads"
          src="https://cdn.ampproject.org/v0/amp-auto-ads-0.1.js">
        </script>
        {/* Google Structured Article */}
        {articleStructured.structured && (
          <script type="application/ld+json">
            {articleStructured.structured.ldJson()}
          </script>
        )}
      </Helmet>
      <div dangerouslySetInnerHTML={{
        __html: `
        <amp-auto-ads type="adsense"
          data-ad-client="ca-pub-5524131539322102">
        </amp-auto-ads>
        `
      }} />
      <ThemeProvider theme={theme}>
        <div className={classes.font}>
          <Header seoConfig={seoConfig} categoryList={blogCategories} />
          <div className={classes.siteWidth}>
            <Grid container>
              <Grid item xs={12} sm={9} className={classes.contentsArea}>
                {children}
              </Grid>
              <Grid item xs={12} sm={3}>
                <SideBar siteConfig={seoConfig.siteConfig} blogCategories={blogCategories} />
              </Grid>
            </Grid>
          </div>
          <Footer />
        </div>
      </ThemeProvider>
    </>
  )
}

export default Layout